import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { visma } from 'images'
import Layout from '../../../../layouts'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import { employesUnderline } from 'images/koppelingen'

const dummyContent = {
  content: [{
    title: 'Salarisadministratie met Employes & Visma eAccounting',
    description: 'Employes is een salarispakket met een gebruiksvriendelijke interface, speciaal ontwikkeld voor ondernemers. Door de duidelijke stappenplannen en overzichtelijke schermen, hebben wij de manier van gegevens invoeren in ons systeem zó makkelijk gemaakt, dat je zelf je salarisadministratie kunt doen.'
    }, {
      title: 'Bespaar tijd met een koppeling',
      description: 'Na het doen van een verloning, dienen de kosten van je personeel verwerkt te worden in de boekhouding. Dat kun je doen door handmatig een memoriaalboeking te maken. Maar het overtypen van de journaalposten kan een tijdrovend klusje zijn. Als je de koppeling tussen Employes en Visma eAccounting gebruikt, hoef je het niet meer handmatig over te typen, en staan de kosten direct in de boekhouding. Dat scheelt tijd. Ook voorkom je er fouten mee, omdat je het niet handmatig hoeft over te nemen.'
    }, {
      title: 'Inbegrepen in de prijs',
      description: 'Het gebruik van de koppeling is geheel gratis als je klant bent bij Employes. Ben je benieuwd hoe het werkt? Maak dan geheel vrijblijvend een proefaccount aan.'
  }]
}

class Visma extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <main className="koppeling single">

          <Helmet>
            <title>Koppeling Visma eAccounting & Employes | Salarisadministratie</title>
            <meta name="description" content="Automatisch je journaalposten importeren in je Visma eAccounting boekhouding met de koppeling van Employes."/>
            <meta itemprop="name" content="Koppeling Visma eAccounting & Employes | Salarisadministratie" />
            <meta itemprop="description" content="Automatisch je journaalposten importeren in je Visma eAccounting boekhouding met de koppeling van Employes."/>
            <meta itemprop="image" content="/static/meta-img.png" />

            {/* OpenGraph tags */}
            <meta property="og:url" content="https://employes.nl/product/koppelingen/visma-eaccounting/" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Koppeling Visma eAccounting & Employes | Salarisadministratie" />
            <meta property="og:description" content="Automatisch je journaalposten importeren in je Visma eAccounting boekhouding met de koppeling van Employes." />
            <meta property="og:image" content="https://employes.nl/static/meta-img.png" />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Koppeling Visma eAccounting & Employes | Salarisadministratie" />
            <meta name="twitter:description" content="Automatisch je journaalposten importeren in je Visma eAccounting boekhouding met de koppeling van Employes." />
            <meta name="twitter:image" content="https://employes.nl/static/meta-img.png" />

          </Helmet>


          <header className="koppeling visma padding-xxl">
            <div className="flakes flakes--left"></div>
            <div className="flakes flakes--right"></div>

            <div className="container-sm">
              <div className="grid yg align-middle">
                <div className="col-6">
                  <h1>Koppel Visma eAccounting aan <span className="hide-mobile">je salarisadministratie in</span> Employes.</h1>
                </div>

                <div className="col-6">
                  <div className="koppeling-image">
                    <img className="margin-s-bottom" src={employesUnderline}/>

                    <svg className="margin-s-bottom" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                      <path fill="#DAE6F0" fillRule="evenodd" d="M167,130 L198,130 L198,131 L167,131 L167,163 L165,163 L165,131 L134,131 L134,130 L165,130 L165,99 L167,99 L167,130 Z" opacity=".4" transform="translate(-134 -99)"/>
                    </svg>

                    <img src={visma}/>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="section-content padding-xl-top">
            <div>
                {dummyContent.content.map((section, idx) => {
                  const firstObjectKey = Object.keys(section)[0]
                  const isImage = (firstObjectKey === 'image')
                  const { title, description } = section

                  return (
                    <div className="section margin-m-bottom" key={idx}>
                      <div className={`container-${ isImage ? 'md image' : 'sm' }`} key={idx}>
                        <div className="grid yg center">
                          <div className={`col-12 ${isImage ? 'no-pad image-container' : 'padding-m'}`}>
                          {{
                            title: (
                              <div className="text">
                                <h4>{ title }</h4>
                                <p>{ description}</p>
                              </div>
                            ),
                            image: (
                              <img src={section.image}/>
                            )
                          }[firstObjectKey]}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </section>

          <div className="section margin-m-bottom">
            <div className="container-sm">
              <div className="grid yg center">
                <div className="col-12">
                  <Link to="/product/koppelingen/" className="link blue">Terug naar koppelingen</Link>
                </div>
              </div>
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}

Visma.propTypes = {
  data: PropTypes.object
}

export default Visma
